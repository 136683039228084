/* only add custom classes here when its hard to do the styling with the default tailwind */

.lh-elight {
  line-height: 1.15;
}

.pt-70-p {
  padding-top: 70%;
}

.polygon {
  margin-right: -20px;
  margin-top: -54px;
}

.od-popup .carousel .slide {
  opacity: 0.7;
}

.od-popup .carousel .slide.selected {
  opacity: 1 !important;
}

.profile-upload {
  height: 170px;
  width: 170px;
}

.camera-hover {
  height: 120px;
  width: 120px;
  position: absolute;
  margin-top: -120px;
  cursor: pointer;
  left: 50%;
  display: block !important;
  transform: translate(-50%, 0);
}

.position-at-center-fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.position-at-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.profile-upload-text {
  opacity: 0.7;
}

.wh-160 {
  height: 160px;
  width: 160px;
}

.wh-170 {
  height: 170px;
  width: 170px;
}
.close-icon {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  z-index: 9999;
}

.close-icon:hover,
.close-icon:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.crop-image-dimensions-container {
  max-width: 600px;
  max-height: 750px;
}

.crop-image-dimensions {
  width: 572px;
  height: 750px;
}

.max-h-95 {
  max-height: 95%;
}

.max-h-90 {
  max-height: 90%;
}

@media only screen and (max-width: 650px) {
  .crop-image-dimensions-container {
    transform: scale(0.5);
    left: -20% !important;
    position: relative !important;
  }
  .w-full-mobile {
    width: 100%;
  }
  .mobile-max-h-95 {
    max-height: 95% !important;
  }
}

@media only screen and (max-width: 650px) {
  .crop-image-dimensions-dialog {
    position: absolute;
    top: 0;
  }
}

.zoom-negative {
  font-size: 28px;
  font-weight: bold;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-right: 10px;
}

.zoom-positive {
  font-size: 28px;
  margin-left: 10px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.zoom-scale {
  width: 200px;
}

.zoom-scale:focus-visible {
  outline: none;
}

.zoom-scale-container {
  display: flex;
  max-width: 240px;
  align-items: center;
  margin: auto;
}

.bg-dark-transparent {
  background: rgb(0 0 0 / 78%);
}

.rb-dark-shadow {
  box-shadow: 19.5041px 19.5041px 3.90083px rgba(0, 0, 0, 0.25);
}

.class-image-popover-pannel {
  margin-top: -100px;
  margin-left: -15%;
}

.class-text-popover-pannel {
  margin-top: -75px;
  margin-left: -22%;
}

@media screen and (max-width: 700px) {
  .class-image-popover-pannel {
    margin-left: 0%;
  }
}

.class-icon-popover-pannel {
  margin-top: -100px;
  margin-left: -71%;
}

@media screen and (max-width: 700px) {
  .class-icon-popover-pannel {
    margin-left: -70%;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.comment-content {
  max-width: 90%;
}

.comment-content:hover .dots-btn-hidden svg {
  display: block !important;
}

.comment-content .dots-btn-hidden svg {
  display: none;
}

/* 
Used for inline spinner 
  transform: translate(-50%, -50%);
** NOTE using transform to get it in the right spot BREAKS the animation!
*/
.inset-center {
  position: absolute;
  top: 40%;
  left: 45%;
}

.navactive:after {
  content: "";
  height: 1px;
  width: 35%;
  background-color: white;
  position: absolute;
  bottom: -0.4em;
  left: 50%;
  transform: translate(-50%);
}

.Toastify__toast--info {
  background: #5ba7c7 !important;
  color: #fff !important;
}

.hover-black:hover path {
  stroke: black !important;
}

.header-notifications {
  position: absolute;
  left: 80%;
  top: 28px;
}

/* CHECKBOX TOGGLE SWITCH */
.toggle-checkbox {
  border-color: rgb(209, 213, 219);
  box-shadow: none !important;
}

.toggle-checkbox:checked {
  background-color: #fff !important;
  right: 0;
  border-color: #ee5a52 !important;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #ee5a52;
}

.on-board-tooltip {
  transform: translate3d(-50%, -2px, 1px);
}

.-ml-8-imp {
  margin-left: -2rem !important;
}

/* for card with teacher image in class page */
.teacher-image-container {
  height: 450px;
}
.teacher-image-container img {
  height: 490px;
}
@media screen and (max-width: 700px) {
  .teacher-image-container {
    height: 245px;
  }
  .teacher-image-container img {
    height: 285px;
  }
}

/* play icon for on demand video */
.hover-play-icon:hover .play-icon svg path:nth-child(2) {
  stroke: lightgray;
}

.hover-play-icon:hover .play-icon svg path:nth-child(1) {
  stroke: lightgray;
  fill: lightgray;
}

/* full screen icon for on demand video */
.hover-full-screen:hover path {
  stroke: lightgray;
  fill: lightgray;
}

/* fb landing testimonials */

.w-auto-important {
  width: auto !important;
}
.testimonials .control-dots {
  bottom: 0rem !important;
}

.testimonials.fbland .control-dots {
  bottom: -1rem !important;
  height: 2rem;
}
@media only screen and (min-width: 650px) {
  .testimonials .control-dots {
    bottom: 2rem !important;
  }
}

.testimonials .carousel .control-next.control-arrow:before {
  border-left: 8px solid #767090;
}

.testimonials .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #767090;
}

.testimonials .carousel.carousel-slider .control-arrow {
  background: transparent !important;
}

.testimonials .carousel .control-dots .dot.selected {
  width: 50px;
  border-radius: 20px;
  background: #767090;
}

.bullets-styling ol {
  padding-left: 1rem;
}
.bullets-styling ul {
  padding-left: 1rem;
}
.bullets-styling ol li {
  list-style-type: decimal;
  line-height: 1;
}
.bullets-styling ul li {
  list-style-type: disc;
  line-height: 1;
}

.always-show-tooltip {
  @apply visible z-50;
}

/* trial page */
@media only screen and (min-width: 640px) {
  .sm-w-120-percent {
    width: 120%;
  }
  .sm-w-125-percent {
    width: 125%;
  }

  .md-lh-elight {
    line-height: 1.15 !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 768px) {
  .md-leading-14 {
    line-height: 3.5rem !important;
  }
}
