/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background-color: #e2ded7;
    font-family: ;
  }
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@layer utilities {
  .w-400 {
    width: 400px;
  }
  .w-500 {
    width: 500px;
  }
  .w-600 {
    width: 600px;
  }
  .w-800 {
    width: 800px;
  }
  .h-150 {
    height: 150px;
  }
  .h-500 {
    height: 500px;
  }
}
