.check-circle {
  width: 5rem;
  height: 5rem;
  background-color: #7ecb91;
  border-radius: 50%;
  display: block;
  margin-bottom: 1.7rem;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: circleScale 0.5s ease 1;
  animation-timing-function: cubic-bezier(0.42, 0.97, 0.52, 1.49);
  margin: 0 auto;
  margin-top: 30px;
}
.check-circle__mark {
  display: block;
}
.check-circle__mark:after {
  position: absolute;
  top: 2.5rem;
  left: 1rem;
  transform: translate(0, 0);
  content: "";
  opacity: 0;
  height: 2.5rem;
  width: 1.25rem;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  transform-origin: left top;
  animation-delay: 0.6s;
  animation-duration: 900ms;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
  animation-timing-function: cubic-bezier(0.42, 0.97, 0.52, 1.49);
  animation-fill-mode: forwards;
}
@keyframes circleScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  20% {
    height: 0;
    width: 1.5rem;
    opacity: 1;
  }
  40% {
    height: 2.25rem;
    width: 1.5rem;
    opacity: 1;
  }
  100% {
    height: 2.5rem;
    width: 1.5rem;
    opacity: 1;
  }
}
